import React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import BacktoList from "~/images/common_icon_backtop.svg";

const NotFoundPage = ({ data, location }) => {

  return (
    <>
      <Seo title="404: Not Found" />
      <section className="contentComponent notFoundPage">
        <header>
          <h1 className="heading with-english">404 NOT FOUND</h1>
          <p className="heading is-secondary">恐れ入りますが、ページが見つかりませんでした</p>
        </header>
        <Link className="" to="/" ><BacktoList /></Link>
      </section>
    </>
  )
}

export default NotFoundPage
